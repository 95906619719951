import MainContent from '@interflora/ui-components/build/components/MainContent/MainContent'
import { useRouter } from 'next/router'
import { getOutlineRenderGraph } from 'utils/getRenderGraph'
import Page from '../components/Page/Page'
import SignIn from '../components/SignIn'
import { renderComponents } from '../utils/components'

const Index = (renderGraph: any) => {
  const router = useRouter()
  const { page } = renderGraph
  return (
    <Page renderGraph={renderGraph} onIsSignedIn={() => router.replace('/account')}>
      <>
        {renderComponents(page.components.header)}
        <MainContent>
          <SignIn key={router.query?.success?.toString()} />
        </MainContent>
        {renderComponents(page.components.footer)}
      </>
    </Page>
  )
}

export default Index

export const getStaticProps = (ctx) =>
  getOutlineRenderGraph(ctx, 'DEFAULT', 'sign-in', { title: 'Welcome to Interflora', noIndex: true })
