import { HandleSubmit, RegisterValues, SignInValues } from '@interflora/ui-components/build/common/props'
import SignInUI from '@interflora/ui-components/build/components/SignIn/SignIn'
import { SiteContext } from '@interflora/ui-components/build/utils/common'
import AnalyticsContext from 'context/AnalyticsContext'
import { register, signIn } from 'lib/apolloClient'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useSaveAuthAnalytics } from 'utils/saveAnalyticsForAuthEvents'

const SignIn = () => {
  const router = useRouter()
  const { success } = router.query
  const { getCustomer } = useContext(SiteContext)
  const analytics = useContext(AnalyticsContext)
  const { saveUserJourneyAnalytics } = useSaveAuthAnalytics()

  const onSignIn: HandleSubmit<SignInValues> = async (values) => {
    await signIn(values)
    const isPromoErrorTypeSession = sessionStorage.getItem('promoErrorType')
    getCustomer.refetch().then((data) => {
      data?.data?.customer && analytics.signIn(data.data.customer.customerNumber, data.data.customer.email)
      if (isPromoErrorTypeSession) {
        sessionStorage.removeItem('promoErrorType')
        router.replace('/basket')
      } else router.replace('/account')
    })
  }

  const onRegister: HandleSubmit<RegisterValues> = async (values) => {
    const registrationValues = { ...values, email: values.email.trim() }
    await register(registrationValues)
    analytics.register()
    router.replace('/sign-in?success=registration-success')
  }

  return getCustomer.loading || getCustomer.data?.customer?.id ? (
    <h1>Loading</h1>
  ) : (
    <SignInUI
      success={success?.toString()}
      onSignIn={onSignIn}
      onRegister={onRegister}
      saveUserJourneyAnalytics={saveUserJourneyAnalytics}
    />
  )
}

export default SignIn
